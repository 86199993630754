<template>
  <vee-form @invalid-submit="onInvalidSubmit" @submit="onSubmit">
    <slot />
  </vee-form>
</template>

<script lang="ts" setup>
import { Form as VeeForm, type InvalidSubmissionHandler } from "vee-validate";

import { type FormState, FormStateKey } from "../composables/form.hook";

const properties = defineProps<{
  submitting?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (event: "submit"): void;
}>();

const onSubmit = () => emit("submit");

const onInvalidSubmit: InvalidSubmissionHandler = (payload) => {
  const firstErroredFieldName = Object.entries(payload.errors)[0]?.[0];
  const fieldElement = document.querySelector(
    `[data-form-field="${firstErroredFieldName}"]`,
  );

  fieldElement?.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

provide<FormState>(FormStateKey, {
  submitting: toRef(properties, "submitting"),
  readonly: toRef(properties, "readonly"),
});
</script>
